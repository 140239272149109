export default {
  displayName: 'Name',
  public: 'Public',
  label: 'Label',
  voiceId: 'Voice ID',
  voiceType: 'Voice Type',
  listen: 'Audio',
  deleteTip: 'Are you sure delete this voice record?',
  myVoices: 'Voice Library',
  addVoice: 'Add Voice',
  modifyVoice: 'Modify Voice',
  denoise: 'Noise Reduction',
  labels: 'Voice Labels',
  voiceFile: 'Voice File',
  isPublic: 'Public',
  text: 'Transcript',
  trimAudio: 'Trim audio to 3-10s, containing only one human voice'
};
