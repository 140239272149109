<template>
  <!--    惊艳的语音合成-->
  <section class="videos-section">
    <div class="video-list">
      <div class="section-header">
        <h2 class="section-title">{{ t('landing.voice.title') }}</h2>
        <p class="section-subtitle">{{ t('landing.voice.subtitle') }}</p>
      </div>
      <div class="section-content">
        <div class="video-container">
          <div class="video-row">
            <template v-for="(video, index) in videos.slice(0, 3)" :key="index">
              <Video
                :video="video"
                :playVideo="() => playVideo(index)"
                :stopVideo="() => stopVideo(index)"
                crossorigin="anonymous"
              />
            </template>
          </div>
          <div class="video-row">
            <template v-for="(video, index) in videos.slice(3, 6)" :key="index">
              <Video
                :video="video"
                :playVideo="() => playVideo(index + 3)"
                :stopVideo="() => stopVideo(index + 3)"
                crossorigin="anonymous"
              />
            </template>
          </div>
        </div>
        <div class="section-footer">
          <p class="section-footer-title magical-word">
            {{ t('landing.voice.listFooterDesc') }}
          </p>
          <div class="section-footer-text">
            {{ t('landing.voice.ttsDesc') }}
          </div>
          <a-button class="section-footer-btn" @click="router.push('/tts/creation')">
            {{ t('landing.voice.listFooterBtn') }}
          </a-button>
        </div>
      </div>
    </div>
  </section>

  <!--    一键语言转换-->
  <section class="videos-section" style="background-color: #f6f6f6">
    <div class="video-player">
      <div class="section-header">
        <h2 class="section-title">{{ t('landing.translate.title') }}</h2>
        <p class="section-subtitle">{{ t('landing.translate.subtitle') }}</p>
      </div>
      <div class="section-content">
        <div class="section-footer">
          <p class="section-footer-title colorful-word">
            {{ t('landing.translate.listFooterDesc') }}
          </p>
          <div class="section-footer-text">
            {{ t('landing.translate.videoDesc') }}
          </div>
          <a-button class="section-footer-btn" @click="router.push('/video/videoList')">
            {{ t('landing.translate.playerFooterBtn') }}
          </a-button>
        </div>
        <div class="video-wrapper">
          <video
            ref="myVideo"
            class="video-player-video"
            :src="selectedVideo?.url"
            type="video/mp4"
            controls
            crossorigin="anonymous"
            @play="onVideoPlay"
            @pause="onVideoPause"
            @seeked="onVideoSeeked"
            play-video=""
          />

          <!-- 翻译音频（默认隐藏），仅用于播放替换音轨 -->
          <audio ref="myAudio" :src="selectedLanguage.payload?.audioUrl" style="visibility: hidden" />
          <a-segmented
            v-model:value="selectedLanguage.value"
            :options="voiceLanguages"
            block
            size="large"
            style="margin-bottom: 1.5rem"
          >
            <template #label="lang">
              <div style="display: flex; align-items: center; gap: 5px" @click="selectLang(lang)">
                <SvgIcon :name="lang.value === 'en' ? 'flag-us' : 'flag-cn'" size="24" />
                <span>{{ lang.payload.name }}</span>
              </div>
            </template>
          </a-segmented>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="tsx" name="LandingVideo">
import i18n from '@/i18n';
import { useRouter } from 'vue-router';

import Video from './video.vue';
import SvgIcon from '@/components/icon/SvgIcon.vue';

interface Video {
  id: number;
  title: string;
  url: string;
  thumbnail: string;
  description: string;
  isPlaying?: boolean;
}

const router = useRouter();
const { t } = i18n.global;
const videos = ref<Video[]>([
  {
    id: 1,
    title: 'Charm',
    url: '/builtin/video/lionqueen.mp4',
    thumbnail: '/builtin/video/lionqueen.jpg',
    description: 'Inspiring and passionate',
    isPlaying: false
  },
  {
    id: 2,
    title: 'Confident',
    url: '/builtin/video/8.mov',
    thumbnail: '/builtin/video/spk_8.jpg',
    description: '',
    isPlaying: false
  },
  {
    id: 3,
    title: 'Funny',
    url: '/builtin/video/dragon.mp4',
    thumbnail: '/builtin/video/dragon.jpg',
    description: '',
    isPlaying: false
  },
  {
    id: 4,
    title: 'Mysterious',
    url: '/builtin/video/13.mp4',
    thumbnail: '/builtin/video/spk_13.jpg',
    description: '',
    isPlaying: false
  },
  {
    id: 5,
    title: 'Relaxing',
    url: '/builtin/video/12.mp4',
    thumbnail: '/builtin/video/spk_12.jpg',
    description: 'Chill and relaxing',
    isPlaying: false
  },
  {
    id: 6,
    title: 'Calm',
    url: '/builtin/video/0.mov',
    thumbnail: '/builtin/video/spk_0.jpg',
    description: 'Smooth and soothing',
    isPlaying: false
  }
]);
const selectedVideo = ref<Video | null>({
  id: 1,
  title: 'translate',
  url: '/builtin/video/translatesample.mp4',
  thumbnail: '/builtin/video/translatesample.png',
  description: 'video 1 description'
});
const voiceLanguages = [
  { value: 'en', payload: { name: 'English' } },
  { value: 'zh', payload: { name: 'Chinese', audioUrl: '/builtin/video/translatesample-cn.mp3' } }
];
const selectedLanguage = ref(voiceLanguages[0]);
const myVideo = ref<HTMLVideoElement>();
const myAudio = ref<HTMLAudioElement>();

/**
 * 语言切换
 * @param language
 */
const selectLang = language => {
  selectedLanguage.value = language;

  const {
    value,
    payload: { audioUrl = '' }
  } = language;

  // 如果切到英语
  if (value === 'en') {
    // 取消静音，让视频原音轨播放
    myVideo.value && (myVideo.value.muted = false);

    // 暂停或清空翻译音频
    if (myAudio.value) {
      myAudio.value.pause();
      myAudio.value.src = '';
    }
  } else {
    // 其它语言：视频静音
    myVideo.value && (myVideo.value.muted = true);

    // 如果这个语言有独立的 audioUrl
    if (audioUrl && myAudio.value) {
      myAudio.value.src = audioUrl;
      myAudio.value.autoplay = true;

      // 同步进度 & 播放
      myAudio.value.currentTime = myVideo.value?.currentTime || 0;
      myVideo.value && myAudio.value.paused && myVideo.value.play();
    }
  }
};

/**
 * 视频播放时，让音频也播放
 */
const onVideoPlay = () => {
  if (!myAudio.value) return;
  // 保持进度一致
  myAudio.value.currentTime = myVideo.value?.currentTime || 0;
  // 如果有翻译音轨则播放，否则不动
  if (selectedLanguage.value.payload.audioUrl) {
    myAudio.value.play();
  }
};

/**
 * 视频暂停时，让音频也暂停
 */
const onVideoPause = () => {
  if (!myAudio.value) return;
  myAudio.value.pause();
};

/**
 * 视频进度拖动结束时，同步音频进度
 */
const onVideoSeeked = () => {
  if (!myAudio.value) return;
  myAudio.value.currentTime = myVideo.value?.currentTime || 0;
};

const playVideo = (index: number) => {
  videos.value[index].isPlaying = true;
};

const stopVideo = (index: number) => {
  videos.value[index].isPlaying = false;
};
</script>

<style lang="less" scoped>
.section-header {
  margin: 2rem 0;
  text-align: center;
  font-size: 28px;

  .section-title {
    margin-bottom: 0.5rem;
  }

  .section-subtitle {
    font-size: 16px;
    color: grey;
    line-height: 1.6;
  }
}

.section-content {
  display: flex;
  gap: 5%;
  align-items: start;
}

.section-footer {
  .section-footer-title {
    margin-top: 15%;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: -0.025rem;
  }

  .section-footer-text {
    flex: auto;
    margin: 30px 0;
    font-size: 16px;
    line-height: 1.375;
    text-align: left;
    font-weight: 400;
    color: #5a5a5a;
  }

  .section-footer-btn {
    background: linear-gradient(90deg, rgba(255, 161, 103, 0.8), #de592a);
    color: white;
    border: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #de592a;
    }
  }
}

.videos-section {
  padding: 5rem 5rem 3rem 5rem;

  .video-list {
    flex: 1;
    min-width: 50%;

    .video-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex-basis: 60%;
      flex-shrink: 0;
      margin-top: 2rem;

      .video-row {
        display: flex;
        gap: 0.5rem;
        height: 180px;

        /* 第一行样式 */
        &:first-child :first-child {
          flex: 2; /* 占据 50% 空间 */
        }

        /* 第二行样式 */
        &:nth-child(2) div:first-child {
          flex: 0.7; /* 占据 25% 空间 */
        }
      }
    }
  }

  .video-player {
    flex: 1;

    .video-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 50%;
      flex-shrink: 0;

      .video-player-video {
        margin-bottom: 1rem;
        border-radius: 12px;
        height: 300px;
      }
    }
  }
}

//pc端
@media (min-width: 768px) {
}

//移动端
@media (max-width: 768px) {
  .section-header {
    margin: 2rem;
    font-size: 1.2rem;
  }

  .section-content {
    flex-direction: column;
  }

  .section-footer {
    flex-direction: column;
    text-align: center;
    padding: 1rem;

    .section-footer-title {
      margin-top: 0.8rem;
      font-size: 20px;
    }

    .section-footer-text {
      margin: 20px 0;
    }
  }

  .videos-section {
    flex-direction: column;
    padding: 1rem;

    .video-list {
      margin-bottom: 5rem;

      .video-container {
        flex-direction: column;
        gap: 1rem;

        .video-row {
          flex-direction: column;
          height: auto;
        }
      }
    }

    .video-player {
      .section-content {
        flex-direction: column-reverse;
      }

      .video-wrapper {
        width: 100%;

        .video-player-video {
          max-width: 100%;
          height: auto;
        }
      }
    }
  }
}

.magical-word {
  background-image: linear-gradient(90deg, #de592a 0%, rgba(123, 2, 197, 0.5) 40%, #4b6cb2 70%, #de592a 100%);
  background-size: 300%;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: linearGradientMove 10s ease infinite alternate;

  @keyframes linearGradientMove {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
}

.colorful-word {
  background-image: linear-gradient(135deg, #3651ac 50%, #bb2831 50%);
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
