export default {
  subPosition: 'POSITION',
  subSize: 'SIZE',
  subColor: 'COLOR',
  subBackGroundColor: 'BG COLOR',
  bottom: 'Bottom Center',
  top: 'Top Center',
  middln: 'Middle Center',
  'bottom-left': 'Bottom Left',
  'bottom-right': 'Bottom Right',
  'top-left': 'Top Left',
  'top-right': 'Top Right',
  'middle-left': 'Middle Left',
  'middle-right': 'Middle Right',
  generate: 'Generate',
  download: 'Download',
  back: 'Back'
};
