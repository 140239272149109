export default {
  oriAudio: 'Original Audio',
  genAudio: 'Generate Audio',
  trim: 'Trim',
  split: 'Split',
  expand: 'Expand',
  insert: 'Insert',
  insertedItem: 'User Inserted Item',
  adjust: 'Adjust',
  play: 'Play',
  pause: 'Pause',
  reGenerate: 'Generate',
  generateDesc:
    "Generate your complete video using 'Generate Video'. Once the generation is complete, you can preview the video through the 'Dubbed' tab.",
  previewTab1: 'Original',
  previewTab2: 'Dubbed',
  exportVideo: 'Generate Video',
  downloadVideo: 'Download Video',
  shareVideo: 'Share Video',
  downloadCaption: 'Download Caption',
  noneJobTip: 'Video not found!',
  sourceTitle: 'Edit Audio',
  sourceDesc:
    "If you're not satisfied with the segmentation of the recognized audio clips, you can split an audio segment into two here, trim unwanted sounds/noise at the beginning or end, or extend unrecognized sounds at the start or end.",
  speaker: 'Switch Speaker',
  adjustTitle: 'Adjust Generate Audio: Adjust the generated speech speed to match your target language.',
  timeDesc: 'min : s : ms',
  duration: 'Duration',
  audioLoadFailed: 'Audio loading failed',
  generateSuc: 'Dubbing generated successfully',
  useGenerate: 'Use Generate Audio',
  useOriginal: 'Use Original Audio',
  originalVoice: 'Original Voice',
  export: 'Export',
  bgm: 'Background Sound',
  caption: 'Caption',
  size: 'Font Size',
  sizeSmall: 'Small',
  sizeMedium: 'Medium',
  sizeLarge: 'Large',
  position: 'Position',
  color: 'Font Color',
  bgColor: 'Font Background Color',
  captionPreview: 'This is the display effect of caption',
  geneProcess: 'Video generation task submitted',
  geneSuc: 'Video generated successfully ',
  downloadSnippet: 'Download Generated Audio',
  deleteSnippet: 'Remove This Snippet',
  deleteSnippetWarn: 'Are you sure delete this snippet?',
  language: 'Generated Language',
  boostStyle: 'Boost Style',
  history:
    "Generate History: Records of the last 5 generations: You can select the one you're most satisfied with to use in the final video generation.",
  insertTip: 'Drag a selection box in the timeline area to insert and add a new snippet. ',
  changesTip:
    'If changes alters speech content, adjust the source and target text manually to ensure consistency for optimal dubbing.',
  trimHelp:
    "You can trim the audio area that 'noiz AI' needs to recognize based on actual conditions. The minimum range is 0.4 seconds.",
  splitHelp:
    'You can split the audio according to the actual situation to make them more reasonable. The minimum range is 0.4 seconds.',
  splitWarn: 'The minimum split range is 0.4 seconds.',
  expandHelp:
    "You can expand the audio area that 'noiz AI' needs to recognize based on actual conditions. The minimum range is 0.4 seconds.",
  rangeHelp:
    "You can adjust the audio range that 'noiz AI' needs to recognize according to actual conditions. The minimum range is 0.4 seconds.",
  generateTip:
    'Not satisfied with the generated audio? You can try the following methods to regenerate it: ① Modify the original text; ② Modify the original audio; ③ Adjust the target audio speed.',
  insertList: 'Inserted Snippet List',
  audioHistoryTip: 'The last {amount} generated records',
  addLibrary: 'Add to Library',
  addLibraryDesc: "Add this audio to your voice library, and you'll be able to generate sounds with its tone.",
  addLibrarySuc: 'Added successfully, you can find it on page <{page}>',
  exportInfo: 'The task of generating video has been submitted and is being processed...'
};
