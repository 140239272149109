import global from './global/global';
import router from './router/router';
import request from './request/request';
import loginModule from './login/login';
import profile from './profile/profile';
import landing from './landing/landing';
import tts from './tts/tts';
import voice from './voice/voice';
import video from './video/video';
import oneClick from './oneClick/oneClick';
import pro from './pro/pro';
import verify from './verify/verify';
import accent from './accent/accent';
import contact from './contact/contact';
import subscription from './payment/subscription';

export default {
  global,
  landing,
  request,
  loginModule,
  verify,
  router,
  tts,
  voice,
  video,
  profile,
  accent,
  oneClick,
  pro,
  contact,
  subscription
};
