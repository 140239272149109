export default {
  hello: '您好',
  logout: '退出登录',
  profileTitle: '个人资料',
  nickname: '昵称',
  email: '邮箱',
  certification: '认证用户',
  avatar: '头像',
  imageTip: '选择的附件应为图片',
  preview: '预览效果',
  account: '账号'
};
