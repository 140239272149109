export default {
  trim: '修剪',
  split: '切割',
  expand: '拓展',
  insert: '插入',
  insertedItem: '用户插入项',
  adjust: '调整',
  play: '播放',
  pause: '暂停',
  reGenerate: '生成',
  generateDesc: '生成最终视频。生成完成后，您可以通过 “转换后视频” 选项卡预览视频。',
  previewTab1: '原视频',
  previewTab2: '转换后视频',
  exportVideo: '生成视频',
  downloadVideo: '下载视频',
  shareVideo: '分享视频',
  downloadCaption: '下载字幕',
  noneJobTip: '视频不合法！',
  sourceTitle: '编辑源配音',
  sourceDesc:
    '如果您对识别的音频片段的分割不满意，您可以在此处将音频片段分成两部分，修剪开头或结尾处不需要的声音/噪音，或者延长开头或结尾处无法识别的声音。',
  speaker: '换说话人',
  adjustTitle: '调整生成音频：调整语音速度以匹配您的目标语言。',
  timeDesc: '分：秒：毫秒',
  duration: '时长',
  audioLoadFailed: '音频加载失败',
  generateSuc: '配音生成成功',
  useGenerate: '使用生成音频',
  useOriginal: '使用原音频',
  originalVoice: '原始音色',
  export: '导出',
  bgm: '背景音',
  caption: '字幕',
  size: '文字大小',
  sizeSmall: '小',
  sizeMedium: '中等',
  sizeLarge: '大',
  position: '位置',
  color: '文字颜色',
  bgColor: '文字背景色',
  captionPreview: '这是字幕的展示效果',
  geneProcess: '视频生成任务已提交',
  geneSuc: '视频生成成功',
  downloadSnippet: '下载转换音频',
  deleteSnippet: '移除该声音片段',
  deleteSnippetWarn: '确认移除该声音片段？',
  language: '生成语种',
  boostStyle: '效果增强',
  history: '历史记录：显示最近 5 条记录，您可以选择最满意的记录用于最终的视频生成。',
  insertTip: '在时间线区域拖拽出选框插入新增片段。',
  changesTip: '如果语音被更改，请手动调整源文本和目标文本，以确保最佳配音的一致性。',
  trimHelp: '您可以根据实际情况裁剪“noiz AI ”要识别的音频范围。最小区间为 0.4 秒。',
  splitHelp: '您可以根据实际情况拆分音频，使它们分句更合理。最小区间为 0.4 秒。',
  splitWarn: '最小裁剪区间为 0.4 秒。',
  expandHelp: '您可以根据实际情况向外拓展“noiz AI ”要识别的音频范围。最小区间为 0.4 秒。',
  rangeHelp: '您可以根据实际情况调整“noiz AI ”要识别的音频范围。最小区间为 0.4 秒。',
  generateTip: '对生成音频不满意？您可以尝试以下方式后重新生成：① 修改原文本；② 修改原音频；③ 调整目标音频速度',
  insertList: '插入片段列表',
  audioHistoryTip: '最近 {amount} 条生成记录',
  addLibrary: '加入声音库',
  addLibraryDesc: '将此声音添加到您的语音库，您将可以在其他地方使用它。',
  addLibrarySuc: '添加成功，你可以在 <{page}> 找到它',
  exportInfo: '生成视频任务已提交，正在处理...'
};
