export default {
  editor: '编辑器',
  tts: '音频创作',
  video: '视频创作',
  creationVoice: '文本转语音',
  voiceList: '语音库',
  dubbingList: '配音列表',
  notification: '通知',
  feedback: '联系合作和反馈',
  discord: '加入我们Discord',
  subscribe: '订阅',
  qqGroup: '官方交流群'
};
