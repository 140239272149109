<template>
  <div class="section-header">
    <h2 class="section-title">{{ t('landing.tts.title') }}</h2>
    <p class="section-subtitle">{{ t('landing.tts.subtitle') }}</p>
  </div>

  <div class="tts-container">
    <!-- 语言选择区域 -->
    <div class="language-selector">
      <h4>{{ t('landing.tts.generate') }}</h4>

      <a-segmented v-model:value="selectedLanguage" :options="languages" block size="middle">
        <template #label="lang">
          <div style="display: flex; align-items: center; gap: 5px" @click="selectLang(lang.value)">
            <SvgIcon :name="`flag-${lang.payload.flag}`" size="24" />
            <span>{{ lang.payload.name }}</span>
          </div>
        </template>
      </a-segmented>
    </div>

    <div class="input-container">
      <!-- 文本输入区域 -->
      <div class="text-input">
        <EditFilled class="text-input-icon" @click="handleEnter" />
        <p class="text-content">{{ inputText }}</p>
        <p class="input-count">({{ inputText.length }}/500)</p>
      </div>

      <!-- 语音角色选择区域 -->
      <div class="voice-characters">
        <div class="voice-row">
          <div
            :class="['card-item', { selected: item.id === selectedVoice?.id }]"
            v-for="item in builtinVoices.slice(0, 5)"
            :key="item.id"
          >
            <video
              v-if="item.id === playingVoice?.id"
              class="video"
              :poster="item.video_cover_url"
              playsinline
              autoplay
              crossorigin="anonymous"
              @ended="playingVoice = null"
            >
              <source :src="item.video_url" type="video/mp4" />
            </video>
            <div v-else class="video-cover-wrapper" @click="selectVoice(item)">
              <img
                class="video-cover"
                :src="item.video_cover_url"
                v-if="item.video_cover_url"
                crossorigin="anonymous"
              />
              <a-empty v-else :description="null" :imageStyle="{ transform: 'translateY(30px)' }" />
              <PlayCircleFilled class="play-icon" @click.stop.prevent="playVoice(item)" />

              <CheckCircleFilled class="video-select" v-if="item.id === selectedVoice?.id" />

              <p class="video-name">{{ item.display_name.split('|')[1] }}</p>
            </div>
          </div>

          <div class="card-item last-item">
            <div class="video-cover-wrapper">
              <img
                class="video-cover"
                :src="builtinVoices[5].video_cover_url"
                v-if="builtinVoices[5].video_cover_url"
                crossorigin="anonymous"
              />
              <div class="video-sign-in" @click="handleEnter">
                <h3>{{ t('landing.tts.signin') }}</h3>
                <p>{{ t('landing.tts.explore') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-button class="play-button" size="large" v-if="audioPlaying" @click="pauseAudio">
      <SvgIcon name="play" />
      {{ t('landing.tts.pause') }}
    </a-button>

    <a-button class="play-button" size="large" v-else @click="playAudio">
      <SvgIcon name="caret-right" />
      {{ t('landing.tts.play') }}
    </a-button>

    <!-- 隐藏的音频元素 -->
    <audio ref="audioPlayer" style="visibility: hidden" @ended="audioPlaying = false"></audio>
  </div>
</template>

<script lang="tsx" setup name="LandingTTS">
import i18n from '@/i18n';
import { useRouter } from 'vue-router';
import { EditFilled, CheckCircleFilled, PlayCircleFilled, PauseOutlined } from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

import SvgIcon from '@/components/icon/SvgIcon.vue';

const { t } = i18n.global;
const router = useRouter();
const audioPlayer = ref<HTMLAudioElement>();
const languages = [
  { value: 'en', payload: { name: 'English', flag: 'us' } },
  { value: 'ja', payload: { name: 'Japanese', flag: 'ja' } },
  { value: 'cn', payload: { name: 'Mandarin', flag: 'cn' } },
  // { value: 'hk', payload: { name: 'Cantonese', flag: 'hk' } },
  { value: 'fr', payload: { name: 'French', flag: 'fr' } },
  // { value: 'de', payload: { name: 'German', flag: 'de' } }
  { value: 'none', disabled: true, payload: { name: t('landing.tts.morelanguage'), flag: 'none' } }
];
const builtinVoices = [
  {
    id: 1,
    display_name: 'Female | Inspiring and passionate',
    video_url: '/builtin/video/6.mov',
    video_cover_url: '/builtin/video/spk_6.jpg'
  },
  {
    id: 2,
    display_name: 'Female | Storytelling and confident',
    video_url: '/builtin/video/8.mov',
    video_cover_url: '/builtin/video/spk_8.jpg'
  },
  {
    id: 3,
    display_name: 'Male | Storytelling and passion',
    video_url: '/builtin/video/4.mov',
    video_cover_url: '/builtin/video/spk_4.jpg'
  },
  {
    id: 4,
    display_name: 'Male | Whispering and cozy (ASMR)',
    video_url: '/builtin/video/3.mov',
    video_cover_url: '/builtin/video/spk_3.jpg'
  },
  {
    id: 5,
    display_name: 'Female | Calm (meditation)',
    video_url: '/builtin/video/2.mov',
    video_cover_url: '/builtin/video/spk_2.jpg'
  },
  {
    id: 6,
    display_name: 'Male | Storytelling',
    video_url: '/builtin/video/1.mov',
    video_cover_url: '/builtin/video/spk_1.jpg'
  }
];
const selectedLanguage = ref('en');
const selectedVoice = ref(builtinVoices[0]);
const playingVoice = ref<any>(null);
const lang_to_text = {
  en: "Welcome! Here's Noiz! \n\nNoiz is an advanced text-to-speech platform designed to bring your words to life with natural, expressive voices. Whether you're creating immersive audio content, enhancing accessibility, or simply converting text for a hands-free experience, Noiz makes it effortless.",
  ja: 'ようこそ！Noizです！\n\nNoizは、自然で表現力豊かな音声であなたの言葉を生き生きとさせる高度なテキスト読み上げプラットフォームです。没入型の音声コンテンツの作成、アクセシビリティの向上、またはハンズフリー体験のためのテキスト変換など、Noizは簡単に実現できます。',
  cn: '欢迎！这是 Noiz！\n\nNoiz 是一个先进的文本转语音平台，能够通过自然且富有表现力的声音将您的文字变为生动的语音。无论您是在创建沉浸式音频内容、提升可访问性，还是将文字转换为免提体验，Noiz 都能让一切变得轻而易举。',
  // hk: '歡迎！呢度係 Noiz！\n\nNoiz係一個先進嘅文字轉語音平台，用自然同有表達力嘅聲音令你嘅文字變得生動。不論你係創作沉浸式音頻內容、提升可訪問性，或者簡單咁樣將文字轉換成免提體驗，Noiz都幫你輕鬆做到。',
  fr: "Bienvenue ! Voici Noiz !\n\nNoiz est une plateforme avancée de synthèse vocale conçue pour donner vie à vos mots avec des voix naturelles et expressives. Que vous créiez du contenu audio immersif, amélioriez l'accessibilité ou convertissiez simplement du texte pour une expérience mains libres, Noiz rend tout cela facile.",
  de: 'Willkommen! Hier ist Noiz!\n\nNoiz ist eine fortschrittliche Text-zu-Sprache-Plattform, die Ihre Worte mit natürlichen und ausdrucksstarken Stimmen zum Leben erweckt. Egal, ob Sie immersive Audioinhalte erstellen, die Barrierefreiheit verbessern oder Texte für ein freihändiges Erlebnis umwandeln möchten – mit Noiz wird es mühelos.'
};
const inputText = ref(lang_to_text[selectedLanguage.value]);
const audioPlaying = ref(false);

const selectLang = (code: string) => {
  selectedLanguage.value = code;
  inputText.value = lang_to_text[code];
};

function selectVoice(voice) {
  selectedVoice.value = voice;
}

function playVoice(voice) {
  if (playingVoice.value?.id === voice.id) return;

  playingVoice.value = voice;
}

const playAudio = () => {
  if (!audioPlayer.value) return;

  audioPlayer.value.src = '/builtin/audio/' + selectedVoice.value.id + '-' + selectedLanguage.value + '.mp3';
  audioPlayer.value.play();
  audioPlaying.value = true;
};

const pauseAudio = () => {
  audioPlayer.value?.pause();
  audioPlaying.value = false;
};

const handleEnter = () => {
  Modal.confirm({
    title: t('landing.tts.confirmTitle'),
    content: t('landing.tts.confirmContent'),
    okText: t('global.okBtnText'),
    cancelText: t('global.cancelBtnText'),
    onOk: () => {
      router.push('/tts/creation');
    }
  });
};
</script>

<style lang="less" scoped>
.section-header {
  margin: 9rem 0 2rem 0;
  text-align: center;
  font-size: 28px;

  .section-title {
    margin-bottom: 0.5rem;
  }

  .section-subtitle {
    font-size: 16px;
    color: grey;
    line-height: 1.6;
  }
}

.tts-container {
  margin: 2rem auto;
  padding: 2rem 4rem;
  background-color: #f9f9f9;

  .language-selector {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 20px;

    :deep(.ant-segmented-group) {
      flex-wrap: wrap;

      .ant-segmented-item {
        flex-grow: 0;
        flex-shrink: 0;
        min-width: auto;
      }

      .ant-segmented-item-selected {
        box-shadow: 1px 4px 2px 0 rgba(0, 0, 0, 0.05);
      }
    }
  }

  .input-container {
    display: flex;
    gap: 2rem;

    .text-input {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-bottom: 2rem;

      .text-content {
        height: 240px;
        overflow: auto;
      }

      .input-count {
        text-align: right;
        font-size: 12px;
        color: #7d7d7d;
      }
    }

    .voice-characters {
      flex: 1;
      min-width: 500px;

      .voice-row {
        display: flex;
        flex-wrap: wrap;

        .card-item {
          position: relative;
          height: 140px;
          width: 160px;
          border: 2px solid transparent;
          border-radius: 8px;
          overflow: hidden;
          cursor: pointer;

          &.selected {
            border: 2px solid var(--primary-color);
          }

          .video {
            height: 100%;
            width: auto;
          }

          .video-cover-wrapper {
            height: 100%;

            .video-cover {
              width: 100%;
              height: 100%;
              object-fit: cover;
              box-sizing: border-box;
            }

            .play-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              width: 30px;
              height: 30px;
              font-size: 30px;
              color: #fff;
              transform: translate(-50%, -50%);
              z-index: 2;
              opacity: 0.6;

              &:hover {
                cursor: pointer;
                opacity: 1;
              }
            }

            .video-select {
              position: absolute;
              top: 4px;
              right: 4px;
              padding: 1px;
              color: #de592a;
              font-size: 14px;
              background-color: #fff;
              border-radius: 50%;
            }

            .video-name {
              position: absolute;
              bottom: 0;
              padding: 10px 16px;
              width: 100%;
              color: #fff;
              font-size: 9px;
              text-align: center;
            }
          }
        }

        .last-item {
          .video-sign-in {
            position: absolute;
            width: 100%;
            height: 100%;
            text-align: center;
            top: 0;
            display: flex;
            flex-direction: column;
            background: #ffffff85;
            justify-content: center;
            gap: 0.5rem;
            font-size: 16px;
            font-weight: bold;
            h3 {
              color: #de592a;
            }
            p {
              color: #36353a;
            }
          }
        }
      }
    }
  }

  .play-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: 140px;
    background: linear-gradient(90deg, #ff9757cc, #de592a);
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #ff6b3d;
    }
  }
}

//pc端
@media (min-width: 768px) {
}

//移动端
@media (max-width: 768px) {
  .section-header {
    margin: 3rem 2rem;
    font-size: 1.2rem;
  }

  .tts-container {
    padding: 1rem;

    .language-selector {
      flex-direction: column;
    }

    .input-container {
      flex-direction: column-reverse;

      .text-input {
        .text-content {
          height: 100px;
        }
      }

      .voice-characters {
        min-width: auto;

        .voice-row {
          .card-item {
            width: 33.33%;
          }
        }
      }
    }
  }
}
</style>
