import qa from './qa';

export default {
  title: 'Purchase a subscription',
  freeTrial: 'Free Trial',
  remaining: 'Credit Remaining',
  unlock: 'Unlock More Credits When You \n',
  subscribeNow: 'Subscribe Now',
  plan: 'Subscription Plans',
  month: 'Month',
  subscribe: 'Subscribe',
  currentPlan: 'Current Plan',
  initCredits: 'Initial Credits',
  monthlyCredits: 'Credits Renewal Monthly',
  managePlan: 'Manage Plan',
  purchaseSuccessful: 'Purchase Successful',
  purchaseFailed: 'Purchase Failed',
  thankPurchase: 'Thank You For Subscribing!',
  thankPurchaseDesc: 'You Have Successfully Subscribe Plan',
  paymentFailed: 'Your Payment Failed',
  paymentFailedDesc: 'Please try again later',
  paymentQA: 'Frequently Asked Questions',
  qa
};
