export default {
  login: 'Log In',
  register: 'Create Account',
  username: 'Username',
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  usernameTip: 'Please enter email address',
  emailInvalidTip: 'Please enter a valid email address',
  passwordTip: 'Please enter password',
  passwordConfirmTip: 'Please enter password again',
  passwordUnMatch: 'Passwords do not match',
  loginSuccessTip: 'Login success！',
  registerAccept: "Please read and agree to the 'Terms of Service' and 'Privacy Policy' first",
  registerSuccessTip: 'Register success！',
  otherLogin: 'Or',
  willClosePage: 'second left, the page  will be closed.',
  google: 'Log in with Google',
  loginTitle: 'Log In to your Account',
  loginDesc: 'You need to log in to use this function. Do you want to log in now?',
  registerTitle: 'Create an Account',
  resetTitle: 'Reset Password',
  remember: 'Remember me',
  forgot: 'Forgot Password?',
  haveNotAccount: "Don't have a Noiz account? ",
  haveAccount: 'Already have a Noiz account? ',
  CreateAccount: 'Create account',
  law1: " An account will be automatically created if click 'Create Account', it means that you have read and agreed to Noiz's ",
  law2: 'Terms of Service',
  law3: ' and ',
  law4: 'Privacy Policy',
  resetTip: 'Enter your email to receive an verification code to reset your password',
  codeTip: 'Enter the verification code you received',
  backToLogIn: 'Back to Log In',
  resetSuccess: 'Password reset successful',
  sendCode: 'Send Code',
  resendCode: "Didn't receive? Resend",
  codeSent: 'The verification code has been sent to {email}, please check',
  newPasswordTitle: 'New Password',
  passwordRule: 'Please enter 8-20 characters(numbers or letters)',
  resetAuthFailed: 'Credential error, please refresh the page and try again',
  simplePasswordTitle: 'Password reset suggestion',
  simplePasswordContent:
    'Your login password is too simple. To ensure account security, it is recommended to reset your password.'
};
