export default {
  titleLogin: '登录您的账号',
  titleVerify: '认证您的账号',
  verifyBtn: '点击认证',
  verifySuccess: '认证成功',
  resend: '认证失败？重发认证邮件',
  resendBtn: '发送认证邮件',
  resendSuccess: '认证邮件发送成功，请到您的邮箱查收',
  pendingVerify: '待认证',
  verified: '已认证',
  goVerify: '去认证'
};
