export default {
  processing: '处理中',
  draft: '可编辑',
  completed: '完成',
  failed: '失败',
  exporting: '导出中',
  videoId: 'ID',
  videoName: '名称',
  jobType: '类型',
  status: '状态',
  createTime: '创建时间',
  layoutStyle: '布局',
  layoutCard: '卡片',
  layoutList: '列表',
  preview: '预览',
  name: '视频名称',
  oneClick: '一键生成',
  pro: '专业生成',
  sourceLanguage: '当前语种',
  targetLanguage: '目标语种',
  uploadDescription: '目前仅支持小于2分钟的视频/音频。',
  uploadVideoError: '视频上传失败。',
  pleaseUploadVideoFirst: '请先上传视频。',
  addDubbing: '创建新的视频'
};
