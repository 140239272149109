import Landing from './index.vue';
import type { TRouter } from '@/types/router';

export default {
  path: '/landing',
  name: 'Landing',
  component: Landing,
  meta: {
    requireAuth: false
  }
} as TRouter;
