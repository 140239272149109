<template>
  <section class="bottom-content">
    <a-menu class="bottom-box" mode="inline" :selectedKeys="[router.currentRoute.value.path]">
      <a-popover placement="right">
        <template #content>
          <img style="width: 120px" src="@/assets/images/common/qq_qr_code.png" alt="qq_qr_code" />
        </template>
        <a-menu-item key="/qqChat">
          <template #icon>
            <QqOutlined />
          </template>

          {{ t('router.qqGroup') }}
        </a-menu-item>
      </a-popover>
      <a-menu-item key="/discord" @click="gotoDiscord()">
        <template #icon>
          <img class="discord-icon" src="@/assets/images/svg/discord-mark-black.svg" alt="discord" />
        </template>
        {{ t('router.discord') }}
      </a-menu-item>
      <a-menu-item key="/contact" @click="router.push('/contact')">
        <template #icon> <MessageOutlined /> </template>
        {{ t('router.feedback') }}
      </a-menu-item>
      <!-- <a-menu-item key="/notification" @click="router.push('/notification')">
        <template #icon> <BellOutlined /> </template>
        {{ t('router.notification') }}
      </a-menu-item> -->
      <a-menu-item key="setting">
        <template #icon> <GlobalOutlined /> </template>
        <a-select v-model:value="useEvent.event.lang" :bordered="false">
          <a-select-option value="en">EN</a-select-option>
          <a-select-option value="zh">中文</a-select-option>
        </a-select>
      </a-menu-item>
      <a-menu-item key="/user/profile" :class="{ 'user-box': useUser.isLogin }" @click="router.push('/user')">
        <template #icon> <UserOutlined /> </template>
        <div class="menu-content" v-if="useUser.user.email">
          <p class="user-name">{{ useUser.user.display_name }}</p>
          <p class="user-email">{{ useUser.user.email || '-' }}</p>
        </div>
        <a-button v-else type="link">{{ t('loginModule.login') }}</a-button>
      </a-menu-item>
    </a-menu>
  </section>
</template>

<script setup lang="tsx" name="AsideBottom">
import { UserOutlined, GlobalOutlined, MessageOutlined, QqOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { userStore } from '@/store/user';
import { eventStore } from '@/store/event';
import i18n from '@/i18n';

const { t } = i18n.global;
const useUser = userStore();
const useEvent = eventStore();
const router = useRouter();

function gotoDiscord() {
  window.open('https://discord.gg/qVRZZ2XwT7', '_blank');
}
</script>

<style lang="less" scoped>
.bottom-content {
  .bottom-box {
    border-right: none;
    .discord-icon {
      width: 14px;
      height: 14px;
    }
    :global(.ant-menu-submenu-popup) {
      display: none;
    }

    :deep(.user-box) {
      align-items: start;
      height: auto;

      .ant-menu-item-icon {
        margin-top: 13px;
      }

      .menu-content {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0;
        height: 65px;

        .user-name {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          width: 100%;
          line-height: 20px;

          > mark {
            margin-left: 3px;
            background: linear-gradient(90deg, #ffc80b 0%, #ea7017 50%, #05c1aa 100%);
            white-space: nowrap;
            color: var(--chakra-colors-transparent);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .user-email {
          width: 100%;
          line-height: 14px;
          color: #7d7d7d;
          font-size: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
