export default {
  login: '登录',
  register: '注册',
  username: '用户名',
  email: '邮箱',
  password: '密码',
  confirmPassword: '确认密码',
  usernameTip: '请输入邮箱账号',
  emailInvalidTip: '请输入有效的邮箱地址',
  passwordTip: '请输入密码',
  passwordConfirmTip: '请再次输入密码',
  passwordUnMatch: '两次输入密码不匹配',
  loginSuccessTip: '登录成功！',
  registerAccept: '请先阅读并同意“服务条款”和“隐私政策”',
  otherLogin: '或者',
  willClosePage: '秒后关闭页面',
  google: 'Google账号登录',
  loginTitle: '登录您的账号',
  loginDesc: '该功能需要登录才能使用，是否现在登录？',
  registerTitle: '注册账号',
  resetTitle: '重置密码',
  remember: '记住账号',
  forgot: '忘记密码?',
  haveNotAccount: '没有 Noiz 账号？',
  haveAccount: '已有 Noiz 账号？',
  CreateAccount: '注册账号',
  law1: ' 点击“创建账户”将自动创建一个账户，这意味着您已经阅读并同意 Noiz 的',
  law2: '服务条款',
  law3: ' 和 ',
  law4: '隐私政策',
  resetTip: '请输入您接收验证码的邮箱地址',
  codeTip: '输入您收到的验证码',
  backToLogIn: '返回登录',
  resetSuccess: '重置密码成功',
  sendCode: '发送验证码',
  resendCode: '没收到？重新发送',
  codeSent: '验证码已发至 {email}，请查收',
  newPasswordTitle: '新密码',
  passwordRule: '请输入8-20位密码，支持数字、字符',
  resetAuthFailed: '凭证出错，请刷新页面重试',
  simplePasswordTitle: '修改密码建议',
  simplePasswordContent: '您的登录密码过于简单，为了保证账号安全建议重置密码'
};
