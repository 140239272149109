import NProgress from 'nprogress'; // progress bar
import { createRouter, createWebHistory } from 'vue-router';
import { TRouter } from '@/types/router';
import { getUserInfo } from '@/utils/auth';

import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({ showSpinner: false });

// 路由拆解：自动引入每个单页的路由 .src/page/*/router.ts
const moduleRoutes = [];
const modules = import.meta.glob('@/pages/*/router.ts', { eager: true }); // 只获取首级路由

for (const path in modules) {
  // @ts-ignore
  moduleRoutes.push(modules[path].default);
}

export const routes: TRouter[] = [{ path: '/', redirect: '/landing' }, ...moduleRoutes].flat();

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      el: '#app',
      top: 0,
      behavior: 'smooth'
    };
  }
});

/**
 * 路由守卫
 */
router.beforeEach((to, from, next) => {
  NProgress.start();

  if (to.meta?.requireAuth) {
    getUserInfo().then((isLogin: boolean) => {
      if (!isLogin) {
        next({
          path: '/login',
          query: { redirect: to.fullPath }
        });
      } else {
        next();
      }
      NProgress.done();
    });
  } else {
    next();
    NProgress.done();
  }
});
