export default {
  subPosition: '位置',
  subSize: '大小',
  subColor: '颜色',
  subBackGroundColor: '背景色',
  bottom: '底部居中',
  top: '顶部居中',
  middle: '中间居中',
  'bottom-left': '底部左对齐',
  'bottom-right': '底部右对齐',
  'top-left': '顶部左对齐',
  'top-right': '顶部右对齐',
  'middle-left': '中间左对齐',
  'middle-right': '中间右对齐',
  generate: '生成',
  download: '下载',
  back: '返回'
};
