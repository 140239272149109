<template>
  <footer class="footer-container">
    <p>© 2024-{{ currentYear }} {{ t('landing.footer.companyname') }}</p>
    <a class="link-btn" @click="showModal(t('landing.privacy'), '/builtin/law/privacy-policy.html')">{{
      t('landing.privacy')
    }}</a>
    <a class="link-btn" @click="showModal(t('landing.terms'), '/builtin/law/term-of-service.html')">{{
      t('landing.terms')
    }}</a>
    <a class="link-btn badge" href="https://github.com/babysor/MockingBird">Backed by MockingBird</a>
  </footer>
</template>

<script setup lang="tsx" name="LandingFooter">
import { Modal } from 'ant-design-vue';
import i18n from '@/i18n';
import { useRouter } from 'vue-router';

const router = useRouter();
const { t } = i18n.global;
const currentYear = new Date().getFullYear();

/**
 * Function to Show Modal
 * @param title
 * @param src
 */
const showModal = (title: string, src: string) => {
  Modal.info({
    title,
    width: '80%',
    content: h('iframe', {
      style: 'width: 100%; height: 600px; border: none',
      src
    }),
    onOk() {
      Modal.destroyAll();
    }
  });
};
</script>

<style lang="less" scoped>
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem 2rem;
  font-size: 12px;
  color: #59636e;
  border-top: 1px solid #efefef;

  .link-btn {
    color: #de592a;
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .badge {
    padding: 5px 10px;
    color: #1f2937;
    border: 1px solid #1f2937;
    border-radius: 0.5rem;
    text-decoration: none;
  }
}

//移动端
@media (max-width: 768px) {
  .footer-container {
    gap: 1rem;
  }
}
</style>
