export default {
  edit: 'Edit',
  delete: 'Delete',
  add: 'Create New',
  action: 'Action',
  okBtnText: 'OK',
  applyBtnText: 'Apply',
  cancelBtnText: 'Cancel',
  revoke: 'Revoke',
  save: 'Save',
  addSuc: 'Created successfully',
  addFailed: 'Created failed',
  submitSuc: 'Submit successfully',
  editSuc: 'Modified successfully',
  deleteSuc: 'Deleted successfully',
  copySuc: 'Copied to clipboard',
  copyFailed: 'Failed to copy to clipboard',
  itemRequired: 'This item cannot be empty',
  pre: 'Previous',
  next: 'Next',
  input: 'Please enter ',
  back: 'Go Back',
  createNew: 'Create New',
  downloadSuc: 'Download Success',
  downloading: 'Downloading',
  downloadFail: 'Download failed',
  all: 'All',
  english: 'English',
  chinese: 'Chinese',
  japanese: 'Japanese',
  hongkong: 'Cantonese',
  auto: 'Auto Detect',
  dontAskAgain: "Don't ask again",
  coming: 'Coming Soon...',
  browserNotSupportVideo: 'Your browser does not support the video tag.',
  tips: 'Tips',
  deleteTip: 'Are you sure delete this task?'
};
