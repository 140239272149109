import { TRouter } from '@/types/router';
import { getUserInfo } from '@/utils/auth';

export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/login/register.vue')
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: () => import('@/pages/login/reset.vue')
  },
  {
    path: '/verify',
    name: 'Verify',
    component: () => import('@/pages/login/verify.vue'),
    beforeEnter: async (to, from, next) => {
      /**
       * 这里定义控制台单页应用进入前的导航守卫逻辑
       */
      try {
        await getUserInfo();
        next();
      } catch (e) {
        next();
      }
    }
  },
  {
    path: '/loginTransfer',
    name: 'LoginTransfer',
    component: () => import('@/pages/login/loginTransfer.vue')
  }
] as TRouter[];
