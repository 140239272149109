export default {
  privacy: 'Privacy Policy',
  terms: 'Terms of Service',
  header: {
    enter: 'START FREE'
  },
  voice: {
    title: 'Incredible Voice Synthesis',
    subtitle: 'Effortlessly Create Unique Human-like Voices',
    listFooterDesc: 'Create and Clone Your Voice with Just a 3-10 Second Clip',
    ttsDesc:
      'This means that you only need to record a short clip of your voice, and the system will use AI and speech synthesis technology to quickly generate your voice model. In this way, you can easily add unique sound elements to your project.',
    listFooterBtn: 'START NOW'
  },
  translate: {
    title: 'One-Click Language Conversion ',
    subtitle: 'Effortless Multilingual Switching',
    listFooterDesc: 'One-click multilingual conversion and keep the original voice',
    videoDesc:
      'One video source, you can generate multiple languages through the Noiz AI large model capability. It saves the tedious manual editing or re-recording costs, preserves the original sound quality to the maximum extent, breaks the language barrier, those brings you a double feast of vision and hearing.',
    playerFooterBtn: 'TRY NOW'
  },
  tts: {
    generate: 'Generate text in',
    title: 'Emotional Text-to-Speech',
    subtitle: 'Immersive Audio Experience at Your Fingertips',
    play: 'PLAY',
    pause: 'PAUSE',
    signin: 'Sign In',
    explore: 'to create your own voice',
    morelanguage: 'More ...',
    confirmTitle: 'Ready to Create?',
    confirmContent: 'You will be redirected to the TTS creation page. Continue?'
  },
  footer: {
    companyname: 'Noiz AI'
  }
};
