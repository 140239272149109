export default {
  displayName: '名称',
  public: '公开',
  label: '标签',
  voiceId: '声音ID',
  voiceType: '声音类型',
  listen: '试听',
  deleteTip: '确定删除这条声音记录？',
  myVoices: '语音库',
  addVoice: '添加声音',
  modifyVoice: '修改声音',
  denoise: '自动降噪',
  labels: '声音标签',
  voiceFile: '语音文件',
  isPublic: '是否公开',
  text: '语音文本',
  trimAudio: '修剪音频至3-10秒，只包含一个人声'
};
