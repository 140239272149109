export default {
  titleLogin: 'Log into your account',
  titleVerify: 'Verify your account',
  verifyBtn: 'Click To Verify',
  verifySuccess: 'Verify Success',
  resend: 'Failed? Resend verification email',
  resendBtn: 'Send Verification Email',
  resendSuccess: 'The verification email was sent successfully, please check your mailbox',
  pendingVerify: 'Pending Verification',
  verified: 'Verified',
  goVerify: 'Go To Verification'
};
