import axios from 'axios';
import { eventStore } from '@/store/event';
import { message } from 'ant-design-vue';
import i18n from '@/i18n';

import type { AxiosError, AxiosInstance, AxiosRequestHeaders } from 'axios';
import type { IRequestParams, IRequestResponse, TResponseData } from '@/types/request';

interface MyAxiosInstance extends AxiosInstance {
  (config: IRequestParams): Promise<any>;
  (url: string, config?: IRequestParams): Promise<any>;
}

// 创建axios实例
const axiosInstance: MyAxiosInstance = axios.create({
  timeout: 30000,
  withCredentials: true,
  baseURL: `//${window.location.host}`
});

/**
 * 设置请求头部参数
 * @param config
 */
function setDefaultHeader(config: Partial<AxiosRequestHeaders>) {
  Object.keys(config).forEach((key: string) => {
    axiosInstance.defaults.headers.common[key] = config[key];
  });
}

/**
 * 请求错误消息通知
 * @param msg
 */
function errorNotify(msg?: string) {
  message
    .error({
      content: msg || i18n.global.t('request.unknownError')
    })
    .then();
}

// 初始化拦截器
function initInterceptors() {
  const useEvent = eventStore();

  // 设置post请求头
  setDefaultHeader({
    ['Content-Type']: 'application/json'
  });
  /**
   * 请求拦截器
   * 每次请求前，如果存在token则在请求头中携带token
   */
  axiosInstance.interceptors.request.use(config => {
    useEvent.event.loading = true;

    switch (config.method?.toUpperCase()) {
      case 'GET':
        if (!config.params) config.params = {};
        config.params.language = useEvent.event.lang;
        break;
      case 'POST':
        if (!config.data) config.data = {};
        config.data.language = useEvent.event.lang;
        break;
      default:
        break;
    }

    return config;
  });

  // 响应拦截器
  axiosInstance.interceptors.response.use(
    // 请求成功
    (response: IRequestResponse<TResponseData>): TResponseData['data'] | Promise<TResponseData['data']> => {
      useEvent.event.loading = false;

      const {
        data: { data, message = '', code = 0 }
      } = response;

      const httpConfig: IRequestParams = response.config;
      useEvent.event.loading = false;

      if (response.status !== 200 || code !== 0) {
        errorHandle({ ...response } as never, message);
        return Promise.reject(httpConfig.notClassified ? response.data : data);
      }
      return httpConfig.notClassified ? response.data : data;
    },
    // 请求失败
    (error: AxiosError<TResponseData, IRequestParams>): Promise<any> => {
      useEvent.event.loading = false;
      errorHandle(error, error?.message || i18n.global.t('request.unknownError'));

      return Promise.reject(error);
    }
  );
}

/**
 * 请求错误处理
 * @param error
 * @param message
 */
function errorHandle(error: AxiosError<TResponseData, IRequestParams>, message?: string) {
  const { config, status, response } = error;

  const calcStatus = status || response?.status;

  // 状态码判断
  switch (calcStatus) {
    case 403:
      errorNotify(i18n.global.t('request.requestForbidden'));
      break;
    case 404:
      errorNotify(i18n.global.t('request.requestEmpty'));
      break;
    default:
      !(config as IRequestParams)?.notNotify && errorNotify(message || '');
  }
}

export default {
  axiosInstance,
  setDefaultHeader,
  initInterceptors
};
