import { WritableComputedRef } from 'vue';
import { defineStore } from 'pinia';
import i18n from '@/i18n';

export interface IEvent {
  // 全局loading
  loading: boolean;
  // 侧边栏固定展开
  affix: boolean;
  // 语音
  lang: WritableComputedRef<'en' | 'zh'>;
}

// 事件store
export const eventStore = defineStore('eventStore', () => {
  const event = reactive<IEvent>({
    loading: false,
    affix: localStorage.getItem('affix') === 'true',
    lang: i18n.global.locale
  });

  /**
   * 设置event通用值
   * @param data
   */
  const setItem = (data: Partial<IEvent>) => {
    if (typeof data === 'object') {
      Object.keys(data).forEach((key: string) => {
        if (event.hasOwnProperty(key)) {
          // @ts-ignore
          event[key] = data[key];
        }
      });
    }
  };

  return { event, setItem };
});
