export default {
  processing: 'Processing',
  draft: 'Draft',
  completed: 'Completed',
  failed: 'Failed',
  exporting: 'Exporting',
  videoId: 'ID',
  videoName: 'Name',
  jobType: 'Type',
  status: 'Status',
  createTime: 'Created',
  layoutStyle: 'Layout',
  layoutCard: 'Card',
  layoutList: 'List',
  preview: 'Preview',
  name: 'NAME',
  oneClick: 'One-Click',
  pro: 'Pro',
  targetLanguage: 'TARGET LANGUAGE',
  sourceLanguage: 'SOURCE LANGUAGE',
  uploadDescription: 'Currently only videos/audios less than 2 minutes are supported.',
  uploadVideoError: 'upload video failed.',
  pleaseUploadVideoFirst: 'Please upload a video first.',
  addDubbing: 'Create New Video'
};
