<template>
  <div class="landing-page">
    <!-- Header -->
    <LandingHeader />

    <!-- Main Content -->
    <main class="main-content">
      <!-- 第一部分: 视频列表和视频播放器 -->
      <LandingVideo />

      <!-- 第二部分: 其他内容 -->
      <LandingTTS />
    </main>

    <!--    Footer-->
    <LandingFooter />
  </div>
</template>

<script setup lang="tsx" name="LandingIndex">
import i18n from '@/i18n';
import { useRouter } from 'vue-router';

import LandingHeader from './components/landingHeader.vue';
import LandingVideo from './components/landingVideo.vue';
import LandingTTS from './components/landingTTS.vue';
import LandingFooter from './components/landingFooter.vue';

const router = useRouter();
const { t } = i18n.global;
</script>

<style lang="less" scoped>
.landing-page {
  font-size: 14px;
  text-align: center;

  .main-content {
    gap: 2rem;
    flex: 1;
    margin: 0 auto;
    padding: 4rem 0 1rem 0;
    max-width: 1920px;
  }
}

//pc端
@media (min-width: 768px) {
}

//移动端
@media (max-width: 768px) {
  .landing-page {
    font-size: 14px;

    .main-content {
      padding: 4rem 0 1rem 0;
    }
  }
}
</style>
