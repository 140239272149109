export default {
  q1: 'Why did my payment fail? How can I solve the problem?',
  a1: 'Possible reasons include: <br /> <ol><li>Your credit/debit card balance is insufficient.</li> <li>Your payment method is not supported.</li> <li>Your bank may have blocked the payment request, please contact your bank to confirm.</li></ol> <br /> If you confirm that the payment method is correct but still cannot pay, please try the following steps: <ol><li>Clear your browser cache and try again.</li> <li>Change the payment method.</li> <li>Contact our customer service team for help.</li></ol>',
  q2: 'Can I get a proof of payment after I complete the payment?',
  a2: '<ol><li>Yes, you can view the payment bill and receipt on the system page after the payment is completed.</li> <li>If you need a formal invoice or have not received a receipt, please contact our customer service team and provide the order number, and we will process it for you as soon as possible.</li></ol>',
  q3: 'Why did my payment succeed but the order status is still unpaid?',
  a3: '<ol><li>Sometimes there may be a delay in updating the payment status, please wait patiently for 5-10 minutes.</li> <li>If the order status is still not updated after waiting, please provide your payment receipt and contact our customer service team, we will solve the problem for you as soon as possible.</li></ol>',
  q4: 'How to upgrade or change the subscription?',
  a4: '<ol><li>Unsubscribe from the original subscription first, and then resubscribe to the new subscription after the billing cycle ends.</li> <li>If there are special circumstances, please contact our customer service team so that we can help you.</li></ol>'
};
