import request from '@/api/request';
import { IRequestParams } from '@/types/request';
import { TLoginGoogleRps, TSetUserReq, TUser } from '@/types/user';

const prefix = 'api/v1';

export default {
  /**
   * 获取用户信息
   */
  getUserInfo: (options?: IRequestParams): Promise<TUser> =>
    request.axiosInstance({
      url: `/${prefix}/self`,
      method: 'GET',
      desc: '获取用户信息',
      notNotify: true,
      ...options
    }),
  /**
   * 更新用户信息
   */
  setUserInfo: (options?: IRequestParams<TSetUserReq>): Promise<TUser> =>
    request.axiosInstance({
      url: `/${prefix}/self`,
      method: 'PUT',
      desc: '更新用户信息',
      ...options
    }),
  /**
   * 登录
   * @param options
   */
  userLogin: (options?: IRequestParams<{ email: string; password: string }>): Promise<{ user_id: number }> =>
    request.axiosInstance({
      url: `/${prefix}/login`,
      method: 'POST',
      desc: '登录',
      ...options
    }),
  /**
   * 登出
   * @param options
   */
  userLogout: (options?: IRequestParams) =>
    request.axiosInstance({
      url: `/${prefix}/logout`,
      method: 'POST',
      desc: '登出',
      ...options
    }),
  /**
   * 用户注册
   * @param options
   */
  userRegister: (options?: IRequestParams<{ email: string; password: string }>): Promise<{ user_id: number }> =>
    request.axiosInstance({
      url: `/${prefix}/register`,
      method: 'POST',
      desc: '注册',
      ...options
    }),
  /**
   * 用户验证
   * @param options
   */
  userVerify: (options?: IRequestParams<{ token: string }>): Promise<any> =>
    request.axiosInstance({
      url: `/${prefix}/verify`,
      method: 'POST',
      desc: '用户验证',
      ...options
    }),
  /**
   * 重新发认证邮件
   * @param options
   */
  resendVerify: (options?: IRequestParams<{ email: string }>): Promise<any> =>
    request.axiosInstance({
      url: `/${prefix}/email`,
      method: 'POST',
      desc: '重新发认证邮件',
      ...options
    }),
  /**
   * google登录
   * @param options
   */
  googleLogin: (options?: IRequestParams<{ redirect: string }>): Promise<TLoginGoogleRps> =>
    request.axiosInstance({
      url: `/${prefix}/login/google`,
      method: 'GET',
      desc: 'google登录',
      ...options
    }),
  /**
   * 上传头像
   * @param data
   */
  uploadAvatar: (data?: FormData): Promise<{ url: string }> =>
    request.axiosInstance({
      method: 'POST',
      desc: '上传头像',
      url: `/${prefix}/uploadavatar`,
      headers: {
        ['Content-Type']: 'multipart/form-data'
      },
      data
    }),
  /**
   * 申请重置密码
   * @param options
   */
  forgotCode: (options?: IRequestParams<{ email: string }>) =>
    request.axiosInstance({
      url: `/${prefix}/password/reset/code`,
      method: 'POST',
      desc: '申请重置密码',
      ...options
    }),
  /**
   * 验证密码重置
   * @param options
   */
  verifyCode: (options?: IRequestParams<{ email: string; code: string }>): Promise<{ reset_key: string }> =>
    request.axiosInstance({
      url: `/${prefix}/password/reset/verify`,
      method: 'POST',
      desc: '验证密码重置',
      ...options
    }),
  /**
   * 重置密码
   * @param options
   */
  resetPassword: (options?: IRequestParams<{ email: string; new_password: string; reset_key: string }>) =>
    request.axiosInstance({
      url: `/${prefix}/password/reset`,
      method: 'PUT',
      desc: '重置密码',
      ...options
    })
};
