export default {
  hello: 'Hello',
  logout: 'Log Out',
  profileTitle: 'Profiles',
  nickname: 'Nickname',
  email: 'Email',
  certification: 'EmailVerification',
  avatar: 'Avatar',
  imageTip: 'The selected attachment should be a picture',
  preview: 'Preview',
  account: 'Account'
};
