<template>
  <header class="landing-header">
    <SvgIcon name="logo" width="100" height="18" class="header-logo" />

    <!--      pc端-->
    <div class="header-right">
      <a-button type="text" @click="showModal(t('landing.privacy'), '/builtin/law/privacy-policy.html')">{{
        t('landing.privacy')
      }}</a-button>
      <a-button type="text" @click="showModal(t('landing.terms'), '/builtin/law/term-of-service.html')">{{
        t('landing.terms')
      }}</a-button>
      <a-select
        class="language-select"
        v-model:value="useEvent.event.lang"
        :options="[
          { value: 'en', label: 'EN' },
          { value: 'zh', label: 'ZH' }
        ]"
      >
        <template #suffixIcon>
          <SvgIcon :name="useEvent.event.lang === 'en' ? 'flag-us' : 'flag-cn'" size="16" style="margin-right: 4px" />
        </template>

        <template #option="{ value }">
          <div style="display: flex; align-items: center; gap: 5px">
            <span>{{ value.toUpperCase() }}</span>
            <SvgIcon :name="value === 'en' ? 'flag-us' : 'flag-cn'" size="16" />
          </div>
        </template>
      </a-select>
      <a-button class="enter-btn" @click="router.push('/tts/creation')">
        {{ t('landing.header.enter') }}
      </a-button>
    </div>

    <!--      移动端-->
    <a-dropdown
      class="header-right-mobile"
      trigger="click"
      placement="bottomRight"
      :getPopupContainer="triggerNode => triggerNode.parentNode"
    >
      <a-button type="default">
        <template #icon><SvgIcon name="dropdown" /></template>
      </a-button>
      <template #overlay>
        <a-menu>
          <a-menu-item>
            <a-segmented v-model:value="useEvent.event.lang" :options="[{ value: 'en' }, { value: 'zh' }]" block>
              <template #label="{ value }">
                <div style="display: flex; align-items: center; gap: 5px">
                  <span>{{ value.toUpperCase() }}</span>
                  <SvgIcon :name="value === 'en' ? 'flag-us' : 'flag-cn'" size="16" />
                </div>
              </template>
            </a-segmented>
          </a-menu-item>
          <a-menu-item>
            <a-button
              size="small"
              type="text"
              block
              @click="showModal(t('landing.privacy'), '/builtin/law/privacy-policy.html')"
              >{{ t('landing.privacy') }}</a-button
            >
          </a-menu-item>
          <a-menu-item>
            <a-button
              size="small"
              type="text"
              block
              @click="showModal(t('landing.terms'), '/builtin/law/term-of-service.html')"
              >{{ t('landing.terms') }}</a-button
            >
          </a-menu-item>
          <a-menu-item>
            <a-button size="small" class="enter-btn" block @click="router.push('/tts/creation')">
              {{ t('landing.header.enter') }}
            </a-button>
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
  </header>
</template>

<script setup lang="tsx" name="LandingHeader">
import { Modal } from 'ant-design-vue';
import i18n from '@/i18n';
import { useRouter } from 'vue-router';
import { eventStore } from '@/store/event';

import SvgIcon from '@/components/icon/SvgIcon.vue';

const router = useRouter();
const { t } = i18n.global;
const useEvent = eventStore();

/**
 * Function to Show Modal
 * @param title
 * @param src
 */
const showModal = (title: string, src: string) => {
  Modal.info({
    title,
    width: '80%',
    content: h('iframe', {
      style: 'width: 100%; height: 600px; border: none',
      src
    }),
    onOk() {
      Modal.destroyAll();
    }
  });
};
</script>

<style lang="less" scoped>
.landing-header {
  position: fixed;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 5rem;
  width: 100%;
  background-color: #fff;
  z-index: 99;
  border-bottom: 1px solid #efefef;

  .header-logo {
    width: 100px;
    flex-shrink: 0;
  }

  .header-right {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: auto;

    .language-select {
      width: 80px;
    }
  }

  .header-right-mobile {
    margin-left: auto;
  }

  .enter-btn {
    background: linear-gradient(90deg, rgba(255, 161, 103, 0.8), #de592a);
    color: white;
    border: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #de592a;
    }
  }
}

//pc端
@media (min-width: 768px) {
  .landing-header {
    .header-right-mobile {
      display: none;
    }
  }
}

//移动端
@media (max-width: 768px) {
  .landing-header {
    padding: 0.5rem;

    .header-right {
      display: none;
    }
  }
}
</style>
