export default {
  privacy: '隐私政策',
  terms: '服务条款',
  header: {
    enter: '免费开始'
  },
  voice: {
    title: '惊艳的语音合成',
    subtitle: '轻松创建独一无二的语音',
    listFooterDesc: '3-10秒的录音，克隆您的专属声音',
    ttsDesc:
      '这意味着您只需简单地录制一小段自己的声音，系统便能AI和语音合成技术，快速生成您的声音模型。通过这种方式，您可以轻松地为您的项目增添独特的声音元素。',
    listFooterBtn: '立即开始'
  },
  translate: {
    title: '一键语言转换',
    subtitle: '轻松多语言切换',
    listFooterDesc: '一键多语言转换，保留原音',
    videoDesc:
      '只需要一个视频源，您便可以通过Noiz AI大模型能力生成多种语言。省去繁琐的手动编辑或重新录制成本，并最大限度保留原音质，打破语言障碍，给您带来视听觉双重盛宴。',
    playerFooterBtn: '立即试用'
  },
  tts: {
    generate: '生成语言',
    title: '情感文本转语音',
    subtitle: '沉浸式音频体验，尽在指尖',
    play: '播放',
    pause: '暂停',
    signin: '登录',
    explore: '创建您的专属语音',
    morelanguage: '更多语言..',
    confirmTitle: '准备好了？',
    confirmContent: '您将被重定向到语音生成页面。继续？'
  },
  footer: {
    companyname: 'Noiz AI'
  }
};
