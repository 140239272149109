export default {
  inputTip: '请输入要转换成声音的内容（最多250字）',
  createCustom: '自定义声音',
  geneButton: '生成',
  uploadOwnVoice: '上传音色（3-10秒纯人声）',
  uploadOwnVoiceTip: '3-10秒内生成效果最佳，低于3秒或超过10秒都会影响生成质量',
  audioLimit: '音频应小于2MB，且只包含一个干净的人声',
  textLimit: '输入文本长度应小于250字',
  drawerTitle: '个性化设置',
  saveVoice: '保存音色',
  quality: '质量预设',
  outputFormat: '输出格式',
  selected: '已选',
  targetLengthS: '目标时长（秒）',
  durationTip: '输出时长',
  qualityTip: '输出质量',
  fileTip: '输出文件格式',
  voiceHistory: '语音历史',
  historyText: '文本',
  unknownVoice: '未知音色',
  voiceInstruction: '音色',
  builtinVoiceTitle: '您的音色库',
  builtinVoiceDesc: '从我们的预置声音库中选择您喜欢的音色.',
  tipToImprove: '提高语音质量的技巧',
  useTip1: '标点符号很重要！尽可能合理使用它们。',
  useTip2: '口音和风格将取决于参考音频和文本。根据需要调整输入，以获得理想的输出效果。',
  useTip3: '改变目标时长以控制语速（但每次只生成一句话）。',
  reset: '重置',
  tutorialTitle: '教程：快速开始使用文本转语音',
  tutorialContent: '从零基础开始创建文本转语音音频的步骤介绍。',
  reductionTip: '将自动去除您上传的音频中非人声部分的声音',
  timbreTip:
    '- 如果您能提供与语音文件一致的准确文本，TTS效果会更佳。<br />- 留空时，我们将自动识别语音，但识别可能不够准确。<br />- 若输入文本与音频内容严重不符，可能会影响TTS效果。<br />建议： 若不确定文本内容，可留空，稍后验证效果。'
};
