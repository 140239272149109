export default {
  edit: '编辑',
  delete: '删除',
  add: '新增',
  action: '操作',
  okBtnText: '确认',
  applyBtnText: '应用',
  cancelBtnText: '取消',
  revoke: '撤销',
  save: '保存',
  addSuc: '创建成功',
  addFailed: '创建失败',
  submitSuc: '提交成功',
  editSuc: '修改成功',
  deleteSuc: '删除成功',
  copySuc: '已复制到剪切板',
  copyFailed: '复制失败',
  itemRequired: '该项不能为空',
  pre: '上一步',
  next: '下一步',
  input: '请输入',
  back: '返回上一页',
  createNew: '重新创建',
  downloadSuc: '下载成功',
  downloading: '下载中',
  downloadFail: '下载失败',
  all: '全部',
  english: '英语',
  chinese: '中文',
  japanese: '日语',
  hongkong: '粤语',
  auto: '自动检测',
  dontAskAgain: '不再提醒',
  coming: '即将推出',
  browserNotSupportVideo: '您的浏览器不支持 video 标签。',
  tips: '提示',
  deleteTip: '确认删除该项？'
};
