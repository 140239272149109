export default {
  q1: '为什么我的支付失败了？要如何解决？',
  a1: '可能的原因包括： <ol><li>您的信用卡/借记卡余额不足。</li> <li>您的支付方式未被支持。</li> <li>您的银行可能阻止了支付请求，请联系您的银行确认。</li></ol> <br /> 如果您确认支付方式无误，但仍然无法支付，请尝试以下步骤： <ol><li>清除浏览器缓存后重新尝试。</li> <li>更换支付方式。</li> <li>联系我们的客服团队获取帮助。</li></ol>',
  q2: '我支付完成后，可以获取支付证明吗？',
  a2: '<ol><li>是的，支付完成后可以在系统页面查看支付账单和收据。</li> <li>如果您需要正式发票或未收到收据，请联系我们的客服团队并提供订单编号，我们会尽快为您处理。</li></ol>',
  q3: '为什么我支付成功了，但订单状态还是未支付？',
  a3: '<ol><li>有时候支付状态更新可能会有一定延迟，请耐心等待 5-10 分钟。</li> <li>如果等待后订单状态仍未更新，请提供支付凭证并联系我们的客服团队，我们会尽快为您解决问题。</li></ol>',
  q4: '如何升级更换套餐？',
  a4: '<ol><li>先取消订阅原套餐，在计费周期结束后再重新订阅新套餐</li> <li>如有特殊情况，请联系我们的客服团队以便我们为您提供帮助。</li></ol>'
};
