import qa from './qa';

export default {
  title: '购买订阅',
  freeTrial: '免费试用',
  remaining: '剩余额度',
  unlock: '解锁更多额度，您可以',
  subscribeNow: '立即订阅',
  plan: '订阅计划',
  month: '月',
  subscribe: '订阅',
  currentPlan: '当前套餐',
  initCredits: '初始额度',
  monthlyCredits: '每月新增额度',
  managePlan: '管理订阅',
  purchaseSuccessful: '支付成功',
  purchaseFailed: '支付失败',
  thankPurchase: '感谢您的订阅！',
  thankPurchaseDesc: '您已成功订阅该方案',
  paymentFailed: '支付失败',
  paymentFailedDesc: '请稍后重试',
  paymentQA: '常见问题',
  qa
};
