export default {
  editor: 'Editor',
  tts: 'Text To Speech',
  video: 'Video Creation',
  creationVoice: 'Audio Creation',
  voiceList: 'Voice Library',
  dubbingList: 'Dubbing List',
  notification: 'Notification',
  feedback: 'Contact & Feedback',
  discord: 'Join Our Discord',
  subscribe: 'Subscribe',
  qqGroup: 'Official Group'
};
