<template>
  <div class="video-card">
    <img v-if="!video.isPlaying" :src="`${ORIGIN}${video.thumbnail}`" alt="video thumbnail" @click="playVideo" />
    <video v-else :src="`${ORIGIN}${video.url}`" type="video/mp4" autoplay crossorigin="anonymous" @ended="stopVideo" />
    <div class="video-info">
      <h3>{{ video.title }}</h3>
      <p>{{ video.description }}</p>
    </div>
    <button v-if="video.isPlaying" class="play-button playing" @click="stopVideo">
      <SvgIcon name="play" width="24" height="24" />
    </button>
    <button v-else class="play-button" @click="playVideo">
      <SvgIcon name="playnow" width="24" height="24" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  video: {
    title: string;
    description: string;
    thumbnail: string;
    url: string;
    isPlaying?: boolean;
  };
  playVideo: () => void;
  stopVideo: () => void;
}>();

const ORIGIN = location.origin;
const video = props.video;
const playVideo = props.playVideo;
const stopVideo = props.stopVideo;
</script>

<style lang="less" scoped>
.video-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  width: 100%;
  flex: 1;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 1px 3px 1px #c1c1c1;
    transform: scale(1.05);

    .video-info {
      display: block;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-info {
    display: none;
  }
}

.video-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  color: white;
}

.video-info h3 {
  margin: 0;
  font-size: 0.8rem;
}

.video-info p {
  margin: 0.5rem 0 0;
  font-size: 0.6rem;
  opacity: 0.8;
}

.play-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  color: white;
  &.playing {
    background: rgba(0, 0, 0, 0.308);
    color: rgba(255, 255, 255, 0.8);
    transition: background-color 0.3s;
  }
}

.play-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.video-card video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
