export default {
  inputTip: 'Please enter the content you want to convert into sound (up to 500 characters)',
  createCustom: 'Custom Voice',
  geneButton: 'Generate',
  uploadOwnVoice: 'Upload voice (3-10s pure vocal)',
  uploadOwnVoiceTip:
    'The best generation effect is achieved within 3-10 seconds. Any time outside this range will affect the generation quality.',
  audioLimit: 'The audio size should be less than 2 Metabytes and it should only contain one clean human voice',
  textLimit: 'The length of input text should be less than 500',
  drawerTitle: 'Custom settings',
  saveVoice: 'Save voice',
  quality: 'Voice quality',
  outputFormat: 'Output format',
  selected: 'Selected',
  select: 'Select',
  targetLengthS: 'Target Length (s)',
  durationTip: 'Output duration',
  qualityTip: 'The output quality',
  fileTip: 'Output file format',
  voiceHistory: 'Voice History',
  historyText: 'Text',
  unknownVoice: 'Unknown',
  voiceInstruction: 'Voice',
  builtinVoiceTitle: 'Voice Library',
  builtinVoiceDesc: ' Select from our built-in voice library. Find the perfect voice for your speech.',
  tipToImprove: 'Tips to Improve Speech Quality',
  useTip1:
    'Punctuation Is Key! It guides the system in interpreting pauses, intonation, and sentence clarity effectively.',
  useTip2:
    'Accent and style are influenced by the reference audio and the text. Tailor your input accordingly for desired results.',
  useTip3:
    "To control speech speed, modify the target duration. Remember, it's best to generate one sentence at a time for precise adjustments.",
  reset: 'Reset',
  tutorialTitle: 'Tutorial: How to start creating Text-to-Speech',
  tutorialContent: 'Introduction of the steps from begining to completely create a text-to-speech audio.',
  reductionTip: 'Automatically removes non-speech sounds from the uploaded audio',
  timbreTip:
    "- Providing an accurate text that matches the audio will improve the TTS quality.<br />- If left blank, we will automatically perform speech recognition, but the results may not be accurate.<br />- If the provided text does not match the audio closely, it may affect the TTS quality.<br />Recommendation: If you're unsure about the text, you can leave it blank and verify the results later."
};
